.btn {
    @apply flex flex-row whitespace-nowrap items-center transition-all duration-200 ease-in-out justify-center text-center relative;
}

.btn-circle {
    @apply justify-center rounded-full flex items-center transition-all duration-200 ease-in-out;
}

/* Button States */
.btn[disabled] {
    @apply bg-surface-600 text-secondary-600 cursor-default;
}


.btn.btn-2xs {
    @apply text-label-sm-bold px-2 uppercase h-5 gap-0.5 text-[10px] rounded-[6px];
}
.btn.btn-xs {
    @apply text-label-bold uppercase px-3 h-8 gap-0.5 rounded-md;
}
.btn.btn-sm {
    @apply text-title-sm-bold uppercase px-4 h-9 gap-2 rounded-md;
}
.btn.btn-md {
    @apply text-title-sm-bold uppercase px-4 h-11 gap-2 rounded-md;
}

.btn-circle.btn-md {
    @apply p-4;
}

.btn-circle.btn-sm {
    @apply p-3;
}

.btn-circle.btn-xs {
    @apply p-2;
}

.btn-circle.btn-2xs {
    @apply p-1;
}

.btn-filled-primary:not([disabled]) {
    @apply bg-primary-500 text-secondary-300 hover:bg-primary-600 active:bg-primary-400 decoration-primary-200;
}

.btn-filled-secondary:not([disabled]) {
    @apply bg-surface text-dark fill-dark hover:bg-primary active:bg-primary-400 decoration-secondary;
}

.btn-filled-tertiary:not([disabled]) {
    @apply bg-surface-600 text-on-tertiary fill-on-tertiary hover:bg-surface-500 active:bg-secondary-700;
}

.btn-filled-light:not([disabled]) {
    @apply bg-surface text-dark fill-dark hover:bg-surface-600 active:bg-surface-500;
}

.btn-filled-dark:not([disabled]) {
    @apply bg-dark text-surface fill-surface hover:bg-secondary-400 active:bg-secondary-200;
}

.btn-filled-success:not([disabled]) {
    @apply bg-success text-on-success hover:bg-success-600 active:bg-success-400;
}

.btn-filled-error:not([disabled]) {
    @apply bg-error text-on-error hover:bg-error-600 active:bg-error-400;
}

.btn-bordered-primary:not([disabled]) {
    @apply bg-primary-500 text-secondary-300 hover:bg-primary-600 active:bg-primary-400 decoration-primary-200;
}

.btn-bordered-secondary:not([disabled]) {
    @apply bg-surface text-dark fill-dark hover:bg-primary active:bg-primary-400 decoration-secondary;
}

.btn-bordered-light:not([disabled]) {
    @apply bg-transparent border-white text-white fill-white hover:bg-white hover:text-dark hover:fill-dark active:text-dark active:fill-dark border;
}

.btn-bordered-dark:not([disabled]) {
    @apply bg-transparent border-dark text-dark fill-dark hover:bg-dark hover:text-white hover:fill-white active:text-white active:fill-white;
}

.btn-bordered-success:not([disabled]) {
    @apply bg-success text-on-success hover:bg-success-600 active:bg-success-400;
}

.btn-bordered-error:not([disabled]) {
    @apply bg-error text-on-error hover:bg-error-600 active:bg-error-400;
}

.btn-underline {
    @apply inline-flex font-medium normal-case underline text-primary-200 decoration-transparent hover:decoration-primary-200;
}

.btn-card {
    @apply justify-center rounded-md text-label-bold bg-transparent text-secondary uppercase py-2 px-2 gap-2 hover:bg-surface-600 outline-1;
}

.btn-info {
    @apply text-label-lg-bold uppercase hover:bg-white/40 py-1 px-1 bg-transparent rounded-md;
}

.btn-sport-dropdown {
    @apply text-title-sm-medium rounded-sm gap-2 py-1 pl-2 pr-2 outline-none bg-dark text-white fill-primary h-[26px] active:scale-95;
}

.btn-menu-tile {
    @apply h-full relative uppercase text-title-medium data-[state=active]:text-title-bold after:absolute after:-bottom-[2px] after:left-0 after:-right-[2px] after:bg-primary-500 after:h-[4px] after:rounded-[4px_4px_0px_0px] after:scale-y-0 after:opacity-0 after:transition-all after:origin-bottom hover:after:scale-y-100 hover:after:opacity-100 data-[state=active]:after:scale-y-100 data-[state=active]:after:opacity-100;
}


@import "tailwindcss/base";
@import "./theme.css";
@import "./webfont.css";
@import "./typography.css";
@import "tailwindcss/components";
@import "./button.css";
@import "./table.css";
@import "tailwindcss/utilities";

@layer base {
  html[data-scroll-locked="0"] {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  html[data-scroll-locked="1"] {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  html, body {
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
  }
  #main-scrollarea-viewport > div {
    display: block !important;
  }
  body {
    /* overflow-y: scroll;
    overflow-x: hidden; */
    background: var(--secondary-100);
    /* padding-right: var(--removed-body-scroll-bar-size); */
  }
  .pull-indicator {
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-lighter);
    transition: transform 0.2s ease-in-out;
    z-index: 555;
  }
  
  .pull-indicator i {
    transition: transform 0.2s ease-in-out;
  }
  
  .pull-indicator.flip i {
    transform: rotate(180deg);
  }
  
  /* override react-remove-scrollbar */
  body {
    /* margin: 0 !important; */
    min-height: 100vh;

  }
  html > body[data-scroll-locked] {
    /* margin-right: var(--removed-body-scroll-bar-size); */
    margin-right: 0px !important;

  }
  /* override the override */
  html > body.remove-scroll {
    overflow-y: hidden !important;
    position: fixed;
    width: 100%;
    height: 100%;
  }
  /* html > body.remove-scroll > .create-pick-trigger {
    pointer-events: none;
  } */
  /* scrollbar styling on chrome
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  ::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 8px;
    transition: all 200ms ease-in-out;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #ccc;
    display: block;
  }

  ::-webkit-scrollbar-thumb:hover ::-webkit-scrollbar {
    display: block;
  } */
}
a:focus-visible,
button:focus-visible,
.no-hint:focus-visible,
.no-link:focus-visible {
  outline: 0 !important;
}
/* get around weird iOS meta behavior */
.no-link {
  text-decoration: none !important;
  color: inherit;
}
.no-link[style] {
  text-decoration: none !important;
}
@layer components {
  .card {
    @apply bg-surface rounded-xl flex flex-col overflow-hidden relative shadow-[0px_2px_10px_0px_rgba(33,39,53,0.05)];
  }
  .placeholder {
    @apply w-full h-full animate-pulse bg-surface-500 rounded-full;
  }
  .loading-shimmer {
    @apply animate-shimmer inline-block w-full h-full bg-no-repeat;
    background: linear-gradient(
      to right,
      #f0f0f0 0%,
      #e0e0e0 50%,
      #f0f0f0 100%
    );
    background-size: 200% 100%;
  }
  .popover {
    @apply rounded-md bg-light shadow-elevation-300 focus:shadow-elevation-400 will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade z-50;
  }
  .container {
    @apply max-w-[77rem] px-4 desktop-grid:px-6 mx-auto w-full;
  }
  .breakout {
    @apply px-0;
  }
  .container-breakout {
    max-width: unset;
    padding-left: unset;
    padding-right: unset;
    margin-left: unset;
    margin-right: unset;
  }
  .main-grid {
    @apply grid items-start grid-cols-1 desktop-grid:grid-cols-[1fr_416px] gap-6;
  }
}
hr {
  @apply border-secondary-700;
}

.vibrate {
  transform: scale(1);
  box-shadow: 0 0 0 rgba(0, 0, 0, 1);
  animation: anim-vibrate 2s infinite;
}
@keyframes anim-vibrate {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(252, 3, 3, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0.375rem rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.indicator-live {
  @apply rounded-full vibrate bg-error-400 w-[0.375rem] h-[0.375rem];
}

.gap {
  @apply flex justify-center relative text-center isolate;

  &:before {
    content: "";
    @apply absolute;
    top: 50%;
    height: 1px;
    width: 100%;
    background: var(--secondary-700);
    z-index: -1;
  }
}

.gap-text {
  @apply bg-white;
  padding: 0 1rem;
}

.btn-social {
  @apply text-title-medium uppercase flex justify-center p-3 rounded-md border border-slate-200;
  align-items: center;
}

.prose {
  @apply max-w-none;
}

@keyframes sliderPagination {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.animate-pagination {
  animation: sliderPagination 8s 1 linear;
}

.pac-container {
  @apply pointer-events-auto;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes pickBounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pickBounce-animation {
  animation: pickBounce 0.4s;
}

.subnav-splide.splide.is-initialized:not(.is-active) .splide__list {
  display: flex;
  gap: 1.5rem;
}

.cloned-pick {
  @apply [transition:transform_700ms_cubic-bezier(0.68,0,0.36,0.51),opacity_0.2s_linear] pointer-events-none;

  .cloned-pick-inner {
    @apply transition-transform duration-[700ms] ease-[cubic-bezier(0.68,-0.6,0.36,0.51)];
  }
}

:root,
.root,
#root,
#docs-root {
  /* Color Schemes */
  --primary: var(--primary-500);
  --secondary: var(--secondary-500);
  --dark: var(--secondary-300);
  --light: var(--surface-700);
  --input-grey: #c7c9d1;
  --grey-soft: #d9d9d9;
  /* Color Palette */
  --primary-100: #375d14;
  --primary-200: #86b927;
  --primary-300: #8fc62a;
  --primary-400: #99d61f;
  --primary-500: #b1ed40;
  --primary-600: #ccfa75;
  --primary-700: #dafc9c;
  --on-primary: var(--dark);
  --on-primary-100: var(--light);
  --on-primary-200: var(--light);
  --on-primary-300: var(--light);
  --on-primary-400: var(--dark);
  --on-primary-500: var(--dark);
  --on-primary-600: var(--dark);
  --on-primary-700: var(--dark);
  --secondary-100: #15171b;
  --secondary-200: #1a1c1e;
  --secondary-300: #232528;
  --secondary-400: #6d7582;
  --secondary-500: #a3a8b1;
  --secondary-600: #c0c3c9;
  --secondary-700: #dee0e3;
  --on-secondary-100: var(--light);
  --on-secondary-200: var(--light);
  --on-secondary-300: var(--light);
  --on-secondary-400: var(--light);
  --on-secondary-500: var(--dark);
  --on-secondary-600: var(--dark);
  --on-secondary-700: var(--dark);
  --surface-100: #e9eaec;
  --surface-300: #f7f8f9;
  --surface-400: #f1f4f8;
  --surface-500: #eff0f1;
  --surface-600: #f4f4f6;
  --surface-700: #ffffff;
  --on-surface-300: var(--dark);
  --on-surface-400: var(--dark);
  --on-surface-500: var(--dark);
  --on-surface-600: var(--dark);
  --on-surface-700: var(--dark);
  --error-100: #531616;
  --error-200: #970b0b;
  --error-300: #db0000;
  --error-400: #ff2929;
  --error-500: #ff6c6c;
  --error-600: #ffcccc;
  --error-700: #ffebeb;
  --on-error-100: var(--light);
  --on-error-200: var(--light);
  --on-error-300: var(--light);
  --on-error-400: var(--dark);
  --on-error-500: var(--dark);
  --on-error-600: var(--dark);
  --on-error-700: var(--error-300);
  --success-100: #16411a;
  --success-200: #058412;
  --success-300: #009b10;
  --success-400: #21ca32;
  --success-500: #72e97e;
  --success-600: #c5fcca;
  --success-700: #e7fee9;
  --on-success-100: var(--light);
  --on-success-200: var(--light);
  --on-success-300: var(--light);
  --on-success-400: var(--dark);
  --on-success-500: var(--dark);
  --on-success-600: var(--dark);
  --on-success-700: var(--success-300);
  --warning-300: #DB9100;
  --warning-700: rgb(248, 233, 204);

  /* Unique Colors */
  --orange: #ffa709;
  --red: #fe0016;
  --hot: #FF5C00;
  /* --color-blue: #0085ca; */
  --blue: #0076b6;

  /* Gradients */
  --primary-gradient: linear-gradient(
      293deg,
      rgba(0, 182, 240, 0.4) 22.6%,
      rgba(51, 255, 0, 0.4) 73.6%
    ),
    var(--light);
  --black-gradient: linear-gradient(136deg, #232528 13.28%, #0d0d0f 97.24%);
  /* Elements */
  --navbar-height: 5rem;
}
th, td {
    @apply px-3 py-3;
}


th {
    @apply text-label-sm-medium uppercase text-secondary;
}

th.highlighted {
    @apply text-secondary-200;
}

th.highlighted .icon {
    @apply fill-secondary-200;
}

td {
    @apply text-sm-medium text-dark;
}

tr {
    @apply text-title-sm-medium text-dark;
}

thead > tr > th {
    @apply pb-2;
}

.table-container {
    @apply overflow-x-auto overflow-y-hidden;
}

table {
    @apply w-full text-left;
}

table.separator tr:not(:last-child) {
    @apply border-b border-b-surface-600;
}

table.banded tr:nth-child(even) td {
    @apply bg-surface-300;
}
table.banded[data-pulse="true"] tr:nth-child(odd) td {
    @apply animate-pulse;
}

table.banded tr:nth-child(even) td.highlighted {
    @apply bg-[#F7F1EB];
}

table.banded tr:nth-child(even) td:first-child {
    @apply rounded-tl-md rounded-bl-md;
}

table.banded tr:nth-child(even) td:last-child {
    @apply rounded-tr-md rounded-br-md;
}

th.highlighted, td.highlighted {
    @apply bg-[#FFF7EE];
}
